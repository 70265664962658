.training_courses
{
    margin-top:60px;
    font-family:'lato';
    padding-top:25px;
    padding-bottom:60px;
    background-color:#F4F4F4;
}
.training_courses h1
{
   font-family:'Lato'; 
   font-weight:bold;
   text-align:center;
   font-size:25px;
   
}
.training_courses .cards
{
    border:1px solid #ffffff;
    box-shadow:1px 1px 1px 1px #ffffff;
    padding:30px;
    background-color:white;
    margin-top:30px;
    
    
}
.training_courses .cards h2
{
    font-size:24px;
    margin-top:-5px;
    font-weight:bold;
}
.training_courses .cards p
{
    font-size:12px;
    font-family:'lato';
    color:#000000;
}
.training_courses .cards .icon
{
    color:#3655C4;
}
.training_courses .cards .time
{
    color:#7b7f85;
     font-family:'Lato'; 
    font-size:12px;
}
.training_courses .tech
{
    margin-top:-15px;
}
.training_courses .tech1
{
    margin-top:15px;
    height:120px;
}
.training_courses .tech .courses
{
    background-color:#AEB9E3;
    padding:5px;
    padding-left:12px;
    padding-right:12px;
    
    color:black;
    font-family:'Lato'; 
    font-size:12px;
}
.training_courses .feature
{
    margin-top:15px;
}
.training_courses .feature p
{
    color:#47474D;
    font-size:12px;
    
}
.training_courses .feature .icon
{
   color:#3655C4;
}
.training_courses .pricing
{
     
    background-color:#AEB9E3;
    margin-top:10px;
    padding:15px;
  
}
.training_courses .view
{
    background-color:#3655C4;color:white;border:none;
    padding:8px;
    margin-left:10px;
    padding-left:10px;
    margin-top:1px;
    padding-right:10px;
}
.training_courses .h2head
{
    height:110px;
}
@media screen and (max-width: 800px)
{
.training_courses .tech1
{
    height:auto;
}
.training_courses .h2head
{
    height:auto;
}
.training_courses
{
    padding-bottom:20px;
}
.training_courses .cards
{
    padding:15px;
    margin-top:25px;
}
.training_courses .cards .logo-icon
{
    height:80px;
  
}
.training_courses .cards h2
{
    font-size:20px;
    margin-top:10px;
}
.training_courses .view
{
    background-color:#3655C4;color:white;border:none;
    padding:8px;
    margin-left:10px;
    padding-left:10px;
    margin-top:2px;
    padding-right:10px;
}
}