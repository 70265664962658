@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Sarabun:wght@300&display=swap');

.banner
{
  background-color:#AEB9E3;
  background-position: center;

  margin-top:50px;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner h1
{
    
    color:black;
    margin-top: 15vh;
    font-size:38px;
    letter-spacing:2px;
    line-height:1.5;
    font-weight:800;
 
    font-family: 'Open Sans Condensed';
}
.banner .btn
{
  border-radius:4;
	      font-family:'Lato';
	   
	      color:white;
	         padding:10px;
	      padding-left:30px;
	      padding-right:30px;
	     
	      margin-top:10px;
	      font-size:13px;
	      background-color:black;   
}

@media screen and (max-width: 800px)
{
.banner
{

  background-repeat: no-repeat;
  background-size: cover;
}
.banner h4
{
    margin-top:-4vh;
    color:#C8C8C8;

}
.banner h1
{
    margin-top: 8vh;
    font-size:29px;
    
}

.banner .btn
{
    margin-top:20px;
}
}

#banner_right
{
    display:inline-block;
}
.right_list
{
    background-color:black;
    padding:8px;
    padding-top:15px;
    font-family:'sarabun';
    font-weight:700;
    font-size:15px;
    margin-top:60px;
    float:right;
    color:white;
    width:100%;
}

.right_list2
{
    background-color:black;
    padding:8px;
    padding-top:15px;
    font-family:'sarabun';
    
    font-weight:700;
    font-size:15px;
    margin-top:10px;
    float:right;
    color:white;
    width:100%;
}

.right_list3
{
    background-color:black;
    padding:8px;
    font-family:'sarabun';
    padding-top:15px;
    font-weight:700;
    font-size:15px;
    margin-top:10px;
    float:right;
    color:white;
    width:100%;
}

@media screen and (max-width: 990px)
{   
    #banner_right
    {
        display:none;
    }
}