#navbar
	  {
	      border-radius:0px;
	      background-color:white;
	      color:black;
	      padding:10px;
	      border:none;
	      box-shadow:2px 2px 2px lightgrey;
	  }
	  #navbar .logo
	  {
	      height:35px;
	      margin-top:-8px;
	  }
	  #nav a
	  {
	      color:black;
	      font-size:16px;
	       
	     font-family:'Lato';
      }
	  #navbar .btn
	  {
	      border-radius:4;
	      font-family:'Lato';
	     margin-left:20px;
	     margin-right:20px;
	      color:white;
	      padding:10px;
	      padding-left:25px;
	      padding-right:25px;
	   
	      margin-top:3px;
	      font-size:16px;
	      background-color:#3655C4;
	  }