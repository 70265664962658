
.footer .footer1
{
    padding-top:3%;
    background-color:#484646;
    padding-bottom:20px;
    
}

.footer .footer_logo
{
    margin-top:3%;
    height:6vh;
    margin-left:-16vh;
}

.footer .icons
{
    color:white;
    font-size:20px;
    margin-top:5px;
}

.footer .contact_details
{
     font-family: 'Sarabun';
    font-size:19px;
    color:white;
}

.footer .contact_row2
{
    margin-top:10px;
}

.footer .footer_row1
{
    
    margin-top:25px;
  
}

.footer .footer_heading
{
    font-size:23px;
    color:#fff;
   margin-top:20px;
    font-family: 'Open Sans';
}

.footer .footer_content
{
    font-size:15px;
    color:lightgrey;
    margin-top:20px;
    font-family: 'Open Sans';
}

.footer .footer_content1
{  
    font-size:15px;
    color:lightgrey;
    font-family: 'Open Sans';
}

.footer-right
{
    margin-left:30px;
}

 .footer-right a {
  
    width: 35px;
    height: 35px;
    float: right;
    background-color: #484646;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-top:20px;
    margin-left: 7px;
    text-decoration:none;
    transition:all .25s;
  }
  
  .footer-right a:hover{
      transform:scale(1.1); -webkit-transform:scale(1.1);text-decoration:none;
      
  }