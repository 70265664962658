.training_feature
{
    margin-top:40px;
}
.training_feature .key-feature
{
    background-color:#AEB9E3;
    color:black;
    border-radius:10px;
    margin-top:20px;
    padding:18px;
    
}
.training_feature .img
{
    height:50px;
}
.training_feature h1
{
     font-size:40px;
    font-weight:bold;
    font-family:'lato';
}
.training_feature h5
{
    margin-top:15px;
       font-size:20px;
    font-family:'lato';
}
.training_feature p
{
    font-family:'lato';
}

@media screen and (max-width: 990px)
{   
.training_feature h1
{
     font-size:33px;
    
}
.training_feature
{
    margin-top:20px;
}
}